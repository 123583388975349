$(document).on('turbolinks:load', function () {
	$("#venue_company_id").on("change", function(){
    updateSelectFields();
  });

	function updateSelectFields() {
    companyId = $('#venue_company').val();

    if(companyId == "") {
      companyId = "All";
    }
    $.ajax({
      url: "/admins/venues/get_filtered_data",
      type: 'get',
      data: {
        company_id: companyId
      },
      success: function(res) {
        $('.area-list option').remove();
        $('.cluster-list option').remove();
        $('.region-list option').remove();
        var areaOptions="";
        var regionOptions= "";
        var clusterOptions= "";
        
        for(var i = 0; i < res["areas"].length; i++) {
          areaOptions += "<option value='" + res["areas"][i].value + "'>" + res["areas"][i].label + "</option>";
        }
        for(var i = 0; i < res["regions"].length; i++) {
          regionOptions += "<option value='" + res["regions"][i].value + "'>" + res["regions"][i].label + "</option>";
        }
        for(var i = 0; i < res["clusters"].length; i++) {
          clusterOptions += "<option value='" + res["clusters"][i].value + "'>" + res["clusters"][i].label + "</option>";
        }
        $( "#venue_region_id" ).append(regionOptions);
        $( "#venue_area_id" ).append(areaOptions);
        $( "#venue_cluster_id" ).append(clusterOptions);
      }
    });
  }
});