let scrollTimer;
let lastMouseMoveTime = Date.now();

function resetScrollTimer() {
    clearTimeout(scrollTimer);
    scrollTimer = setTimeout(logoutUser, 5 * 60 * 1000); // 5 minute in milliseconds
}

function logoutUser() {
    window.location.href = '/operator/logout';
    console.log("Cookie deleted");
}

function checkOperatorCookieExists() {

    // Check if the 'operator' cookie exists
    return document.cookie.split(';').some((item) => item.trim().startsWith('operator='));
}

// Listen for mousemove events
window.addEventListener('mousemove', function () {
    lastMouseMoveTime = Date.now();
});

// Listen for scroll events
window.addEventListener('scroll', function () {
    lastMouseMoveTime = Date.now();
});

// Check if the 'operator' cookie exists before setting the interval
if (checkOperatorCookieExists()) {
    // Check for mouse movement and reset timer every second
    setInterval(function () {
        if (Date.now() - lastMouseMoveTime > 5 * 60 * 1000) { // If no mouse movement or scroll in the five minute
            logoutUser();
        } else {
            resetScrollTimer(); // Reset the timer if there was mouse movement or scroll
        }
    }, 1000); // Check every second

    // Clear timer and delete cookie before page reload
    window.addEventListener('beforeunload', function () {
        clearTimeout(scrollTimer);
        window.location.href = '/operator/logout';
    });
}

