document.addEventListener("DOMContentLoaded", function () {
    var canvas = document.getElementById('signature-pad');
    var signaturePad = new SignaturePad(canvas);
    var savedSignature = localStorage.getItem('sign');
    var clearButton = document.getElementById('clear-signature');
    const preview = document.getElementById('preview');
    preview.style.display = 'none';
    if (savedSignature) {
        clearButton.innerHTML = 'Retry Sign'; // Change the content of the clear button
        canvas.style.display = 'none';
        preview.style.display = '';
        const img = document.createElement('img');
        img.src = localStorage.getItem('sign');
        img.style.maxWidth = '300px'; // Adjust as needed
        img.style.maxHeight = '300px'; // Adjust as needed
        preview.innerHTML = '';
        preview.appendChild(img);
        const submitBtn = document.getElementById("submitBtn");
        submitBtn.disabled = false;
    }

    signaturePad.onEnd = function () {
        console.log('Signature changed');
        // Call your signature function here
        localStorage.setItem('sign', signaturePad.toDataURL('image/png'))
        const form = document.getElementById("myForm");
        const submitBtn = document.getElementById("submitBtn");

        // Add event listeners to form fields
        const formFields = form.querySelectorAll("input, select, textarea, checkbox");
        formFields.forEach(function (field) {
            field.addEventListener("input", validateForm);
        });
        function validateForm() {
            let isValid = true;
            const formFields = form.querySelectorAll("input, select, textarea, checkbox");
            formFields.forEach(function (field) {
                if (!localStorage.getItem('sign') && !$('#customerPresentCheckbox').is(':checked')) {
                    isValid = false;
                }
                if (field.type == "checkbox") {
                    if (field.required && !field.checked) {
                        isValid = false;
                    }
                }
                else {
                    if (field.required && !field.value.trim()) {
                        isValid = false;

                    }
                }

            });
            // Enable or disable submit button based on validation
            submitBtn.disabled = !isValid;
        }
        validateForm();
    };


    clearButton.addEventListener('click', function () {
        clearButton.innerHTML = 'Clear Sign'; // Change the content of the clear button
        signaturePad.clear();
        localStorage.removeItem('sign');
        const submitBtn = document.getElementById("submitBtn");
        submitBtn.disabled = true;
        canvas.style.display = ''; // Remove the display: none style
        preview.style.display = 'none';
    });
});